export const isEmptyString = string => {
    if (typeof string !== 'undefined' && string) {
        return !string.trim().length;
    }

    return true;
}

// Check if any matching elements contain the clicked target
// (Allows multiple elements to use same class names, while maintaining individual functionality. For example .js-close-popup)
export const containsTarget = ($elements, target) => {

    if (typeof $elements === 'undefined' || !$elements || !target) return false

    let elementArray = $elements.toArray()

    if (elementArray) {
        return elementArray.some($item => $item.contains(target))
    }

    return false
}

export const modalChangeIndex = (zIndex) => {
    $('.modal-cover').css('z-index', zIndex)
}

export const toggleModal = (ms = 250, removeOverflow = true) => {
    let $cover = $('.modal-cover')

    $cover.fadeToggle(ms);

    if (removeOverflow) toggleOverflow()
}

export const toggleOverflow = () => {
    $('html').toggleClass('modal-open')
}

export const clearFilters = ($container) => {
    let $inputs = $container.find("input, select, textarea").not("input[type='hidden']")
    let $checkboxes = $container.find("input[type='checkbox']")

    if ($inputs && $inputs.length > 0) {
        $inputs.each(function () {
            // Trigger change needed for select2 dropdowns
            // $(this).val(null).trigger("change");
        })
    }

    if ($checkboxes && $checkboxes.length > 0) {
        $checkboxes.each(function () {
            $(this).prop("checked", false);
        }).promise().done(() => {
            // trigger change only on first one, so change events arent called multiple times.
            $checkboxes.first().trigger('change')
        });
    }
}


export const objectToFormData = (object, formData = new FormData()) => {
    Object.keys(object).forEach((key) => {
        let val = object[key];
        if (Array.isArray(val) || typeof val === 'object') {
            val = JSON.stringify(object[key])
        }
        formData.append(key, val)
    });
    return formData;

}

export const toggleLoader = ($container) => {
    const loader = `<div class="loader js-loader"><div class="loader-wrapper"><div></div><div></div><div></div><div></div></div></div>`;

    let $loader = $container.find('.js-loader')

    if ($loader && $loader.length > 0) {
        $loader.fadeToggle(250)
    } else {
        $container.append(loader)
        $container.find('.js-loader').fadeIn(250)
    }
}

